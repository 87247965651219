export const XHR = controller => {
    Object.assign(controller, {
        async fetch(resource, options = {}) {
            options.headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'text/html, application/json, application/vnd.api+json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
                ...options.headers,
            }

            const response = await fetch(resource, options)

            switch (response.status) {
                case 401:
                case 403:
                case 500:
                    await this._handleWithRedirect(response)
                    break
            }

            return response
        },

        async _handleWithRedirect(response) {
            try {
                const json = await response.json()

                if (json.redirect) {
                    window.location.replace(json.redirect);
                } else {
                    console.error('Response does not contain redirection path', json)
                }
            } catch (error) {
                console.error('Response is not JSON', error)
            }
        }
    })
}