import StimulusClipboardController from '@stimulus-components/clipboard/dist/stimulus-clipboard.mjs';

// Safari and Firefox need a special implementation
// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
export default class extends StimulusClipboardController {
    copy(e) {
        e.preventDefault();
        const i = this.sourceTarget.innerHTML || this.sourceTarget.value
        if (navigator.clipboard) {
            navigator.clipboard.writeText(i).then(() => this.copied())
        } else {
            let textarea = document.createElement("textarea");
            textarea.textContent = i;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                this.copied();
            } catch (err) {
                console.error('Error in copying to clipboard: ', err);
            } finally {
                document.body.removeChild(textarea);
            }

        }
    }
}