import {XHR} from './xhr'

export const fetches = controller => {
    XHR(controller)
    Object.assign(controller, {
        async fetchPartial(resource, options = {}) {
            options = {
                method: 'GET',
                headers: {
                    'Accept': 'text/html',
                    'X-Turbo-Frame': 'no-layout',
                },
                ...options,
            }

            return this.fetch(resource, options)
        },

        async fetchJson(resource, options = {}) {
            options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                ...options,
            }

            return this.fetch(resource, options)
        }
    })
}