import {Controller} from "@hotwired/stimulus"
import {parseLocalizedNumber, localizeNumber} from '../../util'

/**
 * Form controller allows to trigger submit of a form. The controlled element
 * must be a <form>.
 *
 * The format number function allows to check if the content of a textual input
 * is not null and to transform it into a number
 *
 * `toggleChip` allows to control checkbox chips rendered by the default form
 * helper. The desired visual style requires adding a class to a container of
 * a checkbox based on its state. That is achieved through this action.
 *
 *  %form{data: { controller: :form, currentClass: 'current' }}
 *    %fieldset
 *      %div
 *        %label
 *          %input{type: :checkbox, data: { action: 'change->form#toggleChip' }}
 *          Option A
 *      %div.current
 *        %label
 *          %input{type: :checkbox, data: { action: 'change->form#toggleChip' }, checked: :checked}
 *          Option B
 *
 */
export default class extends Controller {
  static targets = ['checkboxChip']
  static classes = ['current']

  submit() {
    this.element.submit()
  }

  formatNumber(event) {
    if (event.target.type === 'text') {
      const val = event.target.value
      if (val === '') {
        return
      }

      event.target.value = localizeNumber(parseLocalizedNumber(val))
    } else {
      console.error(`unsupported input`)
    }
  }

  toggleChip(e) {
    const chip = e.currentTarget.closest('[data-form-target=checkboxChip]');
    if (!chip) {
      return
    }

    chip.classList.toggle(this.currentClass, e.currentTarget.checked)
  }
}