const parseLocalizedNumber = function(str, def = 0.0) {
  let re = /-?[0-9,. ]+/;
  if (!re.test(str)) {
    return def;
  } else {
    // remove everything but digits, comma and period
    let results = re.exec(str);
    let num = results[0].trim();
    let separator = window.I18n.t('number.format.separator');
    let re_sp = new RegExp('[^-0-9' + separator + ']', 'g');
    num = num.replace(re_sp, '');

    if (separator !== '.') {
      let re_carka = new RegExp(separator, 'g');
      num = num.replace(re_carka, '.');
    }

    // parse number
    let number = parseFloat(num);
    if (isNaN(number)) {
      return def;
    } else {
      return number;
    }
  }
};

const addSpaces = function(nStr, round, spaceSeparator) {
  if (round !== undefined) {
    nStr = nStr.toFixed(round);
  }
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? window.I18n.t('number.format.separator') + x[1] : '';
  if (spaceSeparator !== '') {
    // hack to mostly offer correct format for non-CS languages
    let localizedDelimiter = window.I18n.t('number.format.delimiter');
    if (spaceSeparator === ' ' || (spaceSeparator === '&nbsp;' && localizedDelimiter !== '')) {
      spaceSeparator = localizedDelimiter;
    }
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + spaceSeparator + '$2');
    }
  }
  return x1 + x2;
};

const roundNumber = function(num, round) {
  if (round <= 0) {
    return Math.round(num);
  } else {
    return Math.round(num * Math.pow(10, round)) / Math.pow(10, round);
  }
}

const localizeNumber = function(value, round, spaceSeparator = ' ') {
  // determine if any change is possible
  if (isNaN(parseFloat(value))) {
    return value;
  } else {
    if (round !== undefined) {
      value = roundNumber(value, round);
    }
    return addSpaces(value, round, spaceSeparator);
  }
};

export { parseLocalizedNumber, localizeNumber };