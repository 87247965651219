export const disabling = controller => {
  Object.assign(controller, {
    hiddenClassesWithDefault() {
      if (this.hasHiddenClass) {
        return this.hiddenClasses;
      }
      return ['hidden'];
    },

    disable(el) {
      el.hidden = true;
      this.hiddenClassesWithDefault().forEach(klass => el.classList.add(klass));
      if (el.type === 'fieldset') {
        el.disabled = true;
      } else {
        el.querySelectorAll('input, select, textarea').forEach(el => {
          el.disabled = true;
        })
      }
    },

    // Do not define anything like `data-keep-disabled` here.
    // Use `fieldset` tag in the HTML instead in the place of the core element
    // which is passed to the `enable` method.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/disabled#overview
    enable(el) {
      el.hidden = false;
      this.hiddenClassesWithDefault().forEach(klass => el.classList.remove(klass));
      if (el.type === 'fieldset') {
        el.disabled = false
      } else {
        el.querySelectorAll('input, select, textarea').forEach(el => {
          el.disabled = false
        })
      }
    }
  })
}