import {Controller} from "@hotwired/stimulus"
import { useTransition } from "stimulus-use";

/**
 * Flash controller is just copy of
 * https://www.stimulus-components.com/docs/stimulus-notification/ which cannot
 * be imported for some reason
 */
export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 3000
    },
    hidden: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  }

  initialize() {
    this.hide = this.hide.bind(this);
  }

  connect() {
    useTransition(this);

    if (this.hiddenValue === false) {
      this.show()
    }
  }
  show() {
    this.enter()

    if (this.autoCloseValue) {
      this.timeout = setTimeout(this.hide, this.delayValue)
    }
  }

  async hide () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    await this.leave()

    this.element.remove()
  }
}