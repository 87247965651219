import {Controller} from "@hotwired/stimulus"
import {disabling} from "./mixins/disabling";

/**
 * Toggle controller allows a part of the page be conditionally dependent on
 * some other element, typically a user input. The dependent content is hidden
 * by applying class `hidden` + attribute `hidden` + all inputs within it are
 * disabled. When the visibility is toggled, those are removed. If you need
 * finer control, e.g. keep some inputs disabled, you probably want something
 * more custom than this controller.
 *
 * If the controlling element is a checkbox, the behaviour can be inverted,
 * i.e. a checked value means hide, unchecked means show.
 *
 * On `connect`, the controller automatically toggles the state as if a user
 * interaction occurred to ensure the initial state. However, to prevent
 * (sometimes) visible flicker, it is recommended to set content visibility when
 * rendering the page. The initialisation is not performed when the toggler
 * doesn't have a clear state (e.g. link, div) and fully relies on server-side
 * initialisation.
 *
 * Common scenarios:
 *
 *   -# checkbox
 *   %form{data: { controller: 'toggle', toggle_hidden_class: 'hidden' }}
 *     %input{type: :checkbox, data: {action: 'click->toggle#toggle'}}
 *     .dependent-content{data: {toggle_target: 'content'}}
 *
 *   -# inverted checkbox
 *   %form{data: { controller: 'toggle',
 *                 toggle_inverted: 'true',
 *                 toggle_hidden_class: 'hidden'}}
 *     %input{type: :checkbox, data: {action: 'click->toggle#toggle'}}
 *     .dependent-content{data: {toggle_target: 'content'}}
 *
 *   -# radios
 *   %form{data: { controller: 'toggle', toggle_hidden_class: 'hidden'}}
 *     %input{type: :radio, data: {action: 'click->toggle#toggle',
 *                                 toggle_show: 'false'}}
 *     %input{type: :radio, data: {action: 'click->toggle#toggle',
 *                                 toggle_show: 'true'}}
 *     .dependent-content{data: {toggle_target: 'content'}}
 *
 *   -# select
 *   %form{data: { controller: 'toggle', toggle_hidden_class: 'hidden'}}
 *     %select{data: {action: 'change->toggle#toggle'}}
 *       %option{data: { toggle_show: 'false' }} option A
 *       %option{data: { toggle_show: 'true' }} option B
 *       %option{data: { toggle_show: 'true' }} option C
 *       %option{data: { toggle_show: 'false' }} option D
 *     .dependent-content{data: {toggle_target: 'content'}}
 *
 * A checkbox controls the content by its checked state. Radios and selects have
 * to explicitly mark the option which shows the dependent content with
 * `{data: { toggle_show: 'true' }}`.
 *
 *
 * Scenario "We want an item to toggle the visibility of another item"
 *
 * Imagine we have two independent items on the page (they have different
 * toggle_target). They are not next to each other, they are not nested one in
 * the other. When the first item is changed (toggler), we want to change
 * the visibility of the second item (see example below).
 *
 * Previously, we used two instances of toggle_controller (toggle and toggle2)
 * for this purpose. This process has been simplified so that we can now use
 * only one controller instance.
 *
 * Example:
 * We have two pairs of toggler and content which are structured as:
 *
 *   controlled element
 *     toggler 1
 *     content 1
 *       toggler 2
 *     content 2
 *
 * I.e. when we interact with toggler 2 within content 1, we want to change
 * the visibility of content 2.
 *
 * We can use https://stimulus.hotwired.dev/reference/actions#action-parameters
 * to do distinguish the pairs. When we run the toggle Stimulus action, it sends
 * this parameter in event.params - we can read the parameter and continue
 * to use it.
 *
 * So, in our case, for the above elements div1 and div2 we will use
 * the following code:

 *  %button#toggler_1{data: { action: 'click->toggle#toggle' }}
 *    Toggle content 1
 *  %div#toggler_1{data: { toggle_target: 'content' }}
 *    Content 1
 *    %button#toggler_2{data: { action: 'click->toggle#toggle',
 *                              toggle_action_target_param: 'content2' }}
 *      Toggle content 2
 *  %div#content_2{ data: { toggle_target: 'content2' }}
 *    Content 2
 *
 * If not specific, toggle action assumes `content`. However, we can specify
 * toggle_action_target_param with the value 'content2' as a parameter so this
 * toggle action will affect the content2.
 *
 * In toggle_controller, this parameter is visible as actionTarget. We write it
 * as const actionTarget and send it to the show and hide methods.
 *
 * These methods then show/hide the element with the corresponding target,
 * in this case target is div element.
 */
export default class extends Controller {
  static targets = ['content', 'content2']
  static classes = ['hidden']

  connect() {
    disabling(this);
    this.initToggleState();

    const controller = this;
    this.observer = new IntersectionObserver(
      function (_) {
        controller.initToggleState()
      },
      {root: null}
    );

    this.forEachToggler(el => this.observer.observe(el))
  }

  disconnect() {
    super.disconnect();
    this.observer.disconnect()
  }

  initToggleState() {
    this.forEachToggler(el => this.toggle({target: el}, true))
  }

  toggle(event, initializing= false) {
    const actionTarget = event.params?.actionTarget;
    if (event.target.nodeName === 'SELECT') {
      Array(...event.target.selectedOptions).forEach(option => {
        if (option.dataset[this.showDataAttr()] === 'true') {
          this.show(actionTarget)
        } else {
          this.hide(actionTarget)
        }
      })
    } else if (event.target.nodeName === 'INPUT') {
      // checkbox
      if (event.target.type === 'checkbox') {
        if (
          (!event.target.disabled &&
            (
              (event.target.checked && !this.isInverted()) ||
              (!event.target.checked && this.isInverted())
            )
          ) ||
          (event.target.disabled && this.isInverted())
        ) {
          this.show(actionTarget);
        } else {
          this.hide(actionTarget);
        }
      }
      // radio
      else {
        if (event.target.dataset[this.showDataAttr()] === 'true' && event.target.checked) {
          this.show(actionTarget);
        } else {
          this.hide(actionTarget)
        }
      }
    } else if (!initializing) {
      this.hiddenClasses.forEach((value) => {
        this.contentTarget.classList.toggle(value)
      })
    }
  }

  show(actionTarget) {
    if (actionTarget) {
      this[`${actionTarget}Targets`].forEach(t => this.enable(t));
    } else {
      this.contentTargets.forEach(t => this.enable(t));
    }
  }

  hide(actionTarget) {
    if (actionTarget) {
      this[`${actionTarget}Targets`].forEach(t => this.disable(t));
    } else {
      this.contentTargets.forEach(t => this.disable(t));
    }
  }

  forEachToggler(callback) {
    this.scope.findAllElements('[data-action]').forEach(el => {
      if (el.dataset['action'].includes(`->${this.context.module.identifier}#toggle`)) {
        callback(el)
      }
    })
  }

  isInverted() {
    return this.element.dataset[this.invertedDataAttr()] === 'true';
  }

  showDataAttr() {
    return `${this.context.module.identifier}Show`;
  }

  invertedDataAttr() {
    return `${this.context.module.identifier}Inverted`;
  }

  preventDefault(event) {
    event.preventDefault();
  }
}